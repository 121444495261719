import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import CareerView from '../components/views/CareerView/CareerView'

const CareerViewTemplate = ({ data, location }) => {
  return (
    <>
      <Seo
        title={data.prismicCareer.data.title.text}
        customTitle={data.prismicCareer.data.meta_title}
        description={data.prismicCareer.data.meta_description.text || data.prismicCareer.data.body.text}
        image={data.prismicCareer.data.social_image.url}
        slug={location.pathname}
      />
      <CareerView data={data} />
    </>
  )
}

export default withPreview(CareerViewTemplate)

export const PageQuery = graphql`
  query CareerViewTemplateQuery (
    $uid: String!
  ) {
    prismicCareer (
      uid: {
        eq: $uid
      }
    ) {
      uid
      data {
        title {
          text
        }
        application_form_link {
          ...Link
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        locations {
          location {
            text
          }
        }
        contract {
          text
        }
        body {
          html
          text
        }
      }
    }
  }
`
