import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../shared/Button/Button'

// Generic
import BasicPage from '../../shared/BasicPage/BasicPage'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

const CareerView = ({ data }) => {
  return (
    <BasicPage>
      {(data.prismicCareer.data.contract.text || data.prismicCareer.data.locations.length > 0) && (
        <p className='text-center uppercase text-xs tracking-wider text-midnight text-opacity-75 space-x-1 mb-2'>
          {data.prismicCareer.data.contract.text && <span>{data.prismicCareer.data.contract.text}</span>}
          {(data.prismicCareer.data.contract.text && data.prismicCareer.data.locations.length > 0) && (
            <span className='text-primary'>•</span>
          )}
          {data.prismicCareer.data.locations.length > 0 && (
            <span>
              {data.prismicCareer.data.locations.map((item) => {
                return item.location.text
              }).join(', ')}
            </span>
          )}
        </p>
      )}
      <h1 className='h2 text-center mb-10'>{data.prismicCareer.data.title.text}</h1>
      {data.prismicCareer.data.application_form_link && (
        <div className='flex items-center justify-center mb-10'>

          <Button
            color='primary'
            className='w-full sm:w-auto text-center'
            icon
            {...data.prismicCareer.data.application_form_link}
          >
            Download Application
          </Button>
        </div>
      )}
      <HtmlContent
        html={data.prismicCareer.data.body.html}
        className='c-prose'
      />
      {data.prismicCareer.data.application_form_link && (
        <div className='flex items-center justify-center mt-10'>
          <Button
            color='primary'
            className='w-full sm:w-auto text-center'
            icon
            {...data.prismicCareer.data.application_form_link}
          >
            Download Application
          </Button>
        </div>
      )}
    </BasicPage>
  )
}

CareerView.propTypes = {
  data: PropTypes.object.isRequired
}

export default CareerView
